.loading {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: loadingEffect 791ms infinite;
}

@keyframes loadingEffect {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
